@import 'flowbite';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #2c2c54;
}

/* Gradient Range Slider */
#range-slider-gradient {
  height: 24px;
  border-radius: 12px;
  background: #353644;
}

#range-slider-gradient .range-slider__thumb {
  border: 6px solid #fff;
  box-sizing: border-box;
}

#range-slider-gradient .range-slider__thumb[data-lower] {
  background: #0073e6;
}

#range-slider-gradient .range-slider__thumb[data-upper] {
  background: #ee2c2c;
}

#range-slider-gradient .range-slider__range {
  background: linear-gradient(to right, #0073e6, #ee2c2c);
  background-size: 200% 100%;
  background-position: 50% 0;
}

#range-slider-gradient .range-slider__range[data-active],
#range-slider-gradient
  .range-slider__thumb[data-active]
  ~ .range-slider__range {
  animation: move-bg 0.75s infinite linear;
}

@font-face {
  font-family: 'Fancy';
  src: url('./assets/PlaywriteIS-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Sans';
  src: url('./assets/Outfit-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'SansBold';
  src: url('./assets/SpaceMono-Bold.ttf');
}

@font-face {
  font-family: 'SansItalic';
  src: url('./assets/SpaceMono-Italic.ttf');
}

@font-face {
  font-family: 'Hand';
  src: url('./assets/Calligraffitti-Regular.ttf');
}

@font-face {
  font-family: 'Thick';
  src: url('./assets/Staatliches-Regular.ttf');
}


.primary-button {
    background-color: #E0B1F1;
    color: black;
    font-size: 1rem;
    padding: 8px 16px;
    transition: background-color 0.3s ease;
}

.primary-button:hover {
    background-color: #D8A8E8;
}

.primary-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

/* Add shimmer animation for skeleton loading */
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.animate-shimmer {
  animation: shimmer 1.5s infinite linear;
}

/* Add shine animation for the no minimum spend badge */
@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-shine {
  animation: shine 3s infinite ease-in-out;
}

/* Homepage calendar styling */
.homepage-calendar-wrapper {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background-color: #2c2c54;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.homepage-calendar-wrapper .MuiTypography-root {
  font-size: 0.85rem !important;
}

.homepage-calendar-wrapper button {
  transform: scale(1.2);
}

/* Make the day cells larger in the homepage calendar */
.homepage-calendar-wrapper [role="gridcell"] {
  min-width: 32px;
  min-height: 32px;
}

/* Add subtle hover effect */
.homepage-calendar-wrapper [role="gridcell"]:not([aria-disabled="true"]):hover {
  background-color: rgba(224, 177, 241, 0.2);
  transition: background-color 0.2s ease;
}
